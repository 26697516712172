.u-center-text {
    text-align: center !important;
}

.u-margin-bottom-big {
    margin-bottom: 8rem !important;

    @include tablet {
        margin-bottom: 5rem !important;
    }
}

.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include tablet {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
    margin-top: 8rem !important;
}

.u-margin-top-huge {
    margin-top: 10rem !important;
}

.u-text--rose {
    color: $color-primary;
}

.u-align-center {
    align-self: center;
}

.u-space-pading--medium {
    padding: 5rem;
    
    @include phone {
        padding: .5rem 0;
    }
}

.u-text--yellow {
    background-color: $color-secondary;
    padding-right: 1.3rem;
    padding-left: .3rem;
}