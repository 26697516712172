.bg-video {
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .45;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video_espacio {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video_controls {
  max-height: 25rem;
}

.block_grey, .block_white {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

p, body {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.btn-contact {
  width: 30%;
  height: 3rem;
  color: #fff;
  background-color: #696969;
  border: solid;
  border-radius: 40px;
  margin-top: 30px;
  padding: 10px;
}

.btn-contact:link, .btn-contact:visited {
  color: #545454;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.btn-contact:hover {
  color: #fff;
  background-color: #f63372;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #00000026;
}

.btn-contact:active {
  transform: translateY(0);
  box-shadow: 0 1rem 1rem #00000026;
}

.btn-contact a {
  color: #fff;
  text-decoration: none;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.cuadrado {
  width: 15rem;
  height: 15rem;
  border-style: solid;
  border-color: #696969;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  70% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  70% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }

  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row [class^="col-"]:not(:last-child) {
    margin-bottom: 6rem;
    margin-right: 0;
  }

  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc(50% - 3rem);
}

.row .col-1-of-3 {
  width: calc(33.3333% - 4rem);
}

.row .col-2-of-3 {
  width: calc(66.6667% - 2rem);
}

.row .col-1-of-4 {
  width: calc(25% - 4.5rem);
}

.row .col-2-of-4 {
  width: calc(50% - 3rem);
}

.row .col-3-of-4 {
  width: calc(75% - 1.5rem);
}

.column_secondary {
  padding-top: 18rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-text--rose {
  color: #f63372;
}

.u-align-center {
  align-self: center;
}

.u-space-pading--medium {
  padding: 5rem;
}

@media (max-width: 599px) {
  .u-space-pading--medium {
    padding: .5rem 0;
  }
}

.u-text--yellow {
  background-color: #fdd300;
  padding-left: .3rem;
  padding-right: 1.3rem;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.bg-video {
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .45;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video_espacio {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video_controls {
  max-height: 25rem;
}

.block_grey, .block_white {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

p, body {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.btn-contact {
  width: 30%;
  height: 3rem;
  color: #fff;
  background-color: #696969;
  border: solid;
  border-radius: 40px;
  margin-top: 30px;
  padding: 10px;
}

.btn-contact:link, .btn-contact:visited {
  color: #545454;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.btn-contact:hover {
  color: #fff;
  background-color: #f63372;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #00000026;
}

.btn-contact:active {
  transform: translateY(0);
  box-shadow: 0 1rem 1rem #00000026;
}

.btn-contact a {
  color: #fff;
  text-decoration: none;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.cuadrado {
  width: 15rem;
  height: 15rem;
  border-style: solid;
  border-color: #696969;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  70% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  70% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }

  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row [class^="col-"]:not(:last-child) {
    margin-bottom: 6rem;
    margin-right: 0;
  }

  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc(50% - 3rem);
}

.row .col-1-of-3 {
  width: calc(33.3333% - 4rem);
}

.row .col-2-of-3 {
  width: calc(66.6667% - 2rem);
}

.row .col-1-of-4 {
  width: calc(25% - 4.5rem);
}

.row .col-2-of-4 {
  width: calc(50% - 3rem);
}

.row .col-3-of-4 {
  width: calc(75% - 1.5rem);
}

.column_secondary {
  padding-top: 18rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-text--rose {
  color: #f63372;
}

.u-align-center {
  align-self: center;
}

.u-space-pading--medium {
  padding: 5rem;
}

@media (max-width: 599px) {
  .u-space-pading--medium {
    padding: .5rem 0;
  }
}

.u-text--yellow {
  background-color: #fdd300;
  padding-left: .3rem;
  padding-right: 1.3rem;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.header {
  width: 80%;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  display: flex;
  flex-direction: row !important;
}

@media (max-width: 599px) {
  .header {
    width: 100%;
    flex-direction: column !important;
  }
}

.header__logo {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .header__logo {
    width: 100%;
    margin-left: 0;
  }

  .header__logo img {
    width: 70%;
  }

  .header__content {
    flex-direction: column;
    display: flex;
  }
}

.header__text {
  width: 50%;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .header__text {
    width: 100%;
    margin-top: 6rem;
  }
}

.header__text-tittle {
  width: 80%;
  color: #000;
  text-align: left;
}

@media (max-width: 599px) {
  .header__text-tittle {
    width: 80%;
    text-align: center;
    align-self: center;
  }
}

.header__text-p {
  width: 80%;
  text-align: left;
}

@media (max-width: 599px) {
  .header__text-p {
    width: 80%;
    text-align: center;
    align-self: center;
  }
}

.header__text-button {
  width: 25%;
  color: #fff;
  background-color: #545454;
  border: solid;
  border-radius: 30px;
  margin-top: 30px;
}

.header__text-button a {
  font-size: 1.6rem;
}

.bg-video {
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .45;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video_espacio {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video_controls {
  max-height: 25rem;
}

.block_grey, .block_white {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

p, body {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.btn-contact {
  width: 30%;
  height: 3rem;
  color: #fff;
  background-color: #696969;
  border: solid;
  border-radius: 40px;
  margin-top: 30px;
  padding: 10px;
}

.btn-contact:link, .btn-contact:visited {
  color: #545454;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.btn-contact:hover {
  color: #fff;
  background-color: #f63372;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #00000026;
}

.btn-contact:active {
  transform: translateY(0);
  box-shadow: 0 1rem 1rem #00000026;
}

.btn-contact a {
  color: #fff;
  text-decoration: none;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.cuadrado {
  width: 15rem;
  height: 15rem;
  border-style: solid;
  border-color: #696969;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  70% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  70% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }

  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row [class^="col-"]:not(:last-child) {
    margin-bottom: 6rem;
    margin-right: 0;
  }

  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc(50% - 3rem);
}

.row .col-1-of-3 {
  width: calc(33.3333% - 4rem);
}

.row .col-2-of-3 {
  width: calc(66.6667% - 2rem);
}

.row .col-1-of-4 {
  width: calc(25% - 4.5rem);
}

.row .col-2-of-4 {
  width: calc(50% - 3rem);
}

.row .col-3-of-4 {
  width: calc(75% - 1.5rem);
}

.column_secondary {
  padding-top: 18rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-text--rose {
  color: #f63372;
}

.u-align-center {
  align-self: center;
}

.u-space-pading--medium {
  padding: 5rem;
}

@media (max-width: 599px) {
  .u-space-pading--medium {
    padding: .5rem 0;
  }
}

.u-text--yellow {
  background-color: #fdd300;
  padding-left: .3rem;
  padding-right: 1.3rem;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.blockMain {
  width: 100vw;
  height: 50vh;
}

.convierte_ideas {
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0;
  margin-right: 0;
  display: flex;
}

@media (max-width: 599px) {
  .convierte_ideas {
    width: 100%;
    flex-direction: column;
  }
}

.convierte_ideas__text {
  width: 50%;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  display: flex;
}

@media (max-width: 599px) {
  .convierte_ideas__text {
    width: 100%;
  }
}

.convierte_ideas__text--title {
  text-align: left;
  color: #000;
}

@media (max-width: 599px) {
  .convierte_ideas__text--title {
    width: 80%;
  }
}

.convierte_ideas__text--subtitle {
  width: 60%;
  text-align: left;
}

@media (max-width: 599px) {
  .convierte_ideas__text--subtitle {
    width: 80%;
    margin-top: 2rem;
  }
}

.convierte_ideas__img {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.convierte_ideas__img img {
  width: 80%;
}

@media (max-width: 599px) {
  .convierte_ideas__img {
    width: 100%;
    margin-top: 4rem;
  }

  .convierte_ideas__img img {
    width: 80%;
  }
}

.trabajemos_juntos {
  width: 90%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  display: flex;
}

@media (max-width: 599px) {
  .trabajemos_juntos {
    width: 100%;
    flex-direction: column;
  }
}

.trabajemos_juntos__text {
  width: 50%;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  display: flex;
}

@media (max-width: 599px) {
  .trabajemos_juntos__text {
    width: 100%;
  }
}

.trabajemos_juntos__text--title {
  text-align: left;
  color: #000;
}

@media (max-width: 599px) {
  .trabajemos_juntos__text--title {
    width: 80%;
  }
}

.trabajemos_juntos__text--subtitle {
  width: 70%;
  text-align: left;
}

@media (max-width: 599px) {
  .trabajemos_juntos__text--subtitle {
    width: 80%;
  }
}

.trabajemos_juntos__img {
  width: 50%;
  padding: 0;
  display: flex;
}

.trabajemos_juntos__img img {
  width: 80%;
}

@media (max-width: 599px) {
  .trabajemos_juntos__img {
    width: 100%;
    margin-bottom: 4rem;
  }

  .trabajemos_juntos__img img {
    width: 50%;
  }
}

.tu_crecimiento {
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  display: flex;
}

.tu_crecimiento__text {
  width: 70%;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tu_crecimiento__text--title {
  width: 35%;
  text-align: left;
  color: #000;
}

.tu_crecimiento__text--subtitle {
  width: 35%;
  text-align: left;
}

.tu_crecimiento__img {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.clientes {
  width: 80%;
  margin-top: 0;
  margin-right: 0;
}

.clientes h2 {
  padding: 25px;
  font-weight: normal;
}

.clientes p {
  font-size: 1.4rem;
}

.clientes .alliances {
  width: 60%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.clientes--box {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.clientes--box img {
  margin: 15px;
}

.clientes--box .customers img {
  height: 38px;
}

.clientes--box .alliances img {
  height: 54px;
  padding: 10px;
}

.clientes--box .awards img {
  height: 72px;
  padding: 10px;
}

.clientes--box a {
  width: 280px;
  text-align: center;
  color: #666;
  padding: 25px;
  font-size: 1.1rem;
  text-decoration: none;
}

.bg-video {
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .45;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video_espacio {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video_controls {
  max-height: 25rem;
}

.block_grey, .block_white {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

p, body {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.btn-contact {
  width: 30%;
  height: 3rem;
  color: #fff;
  background-color: #696969;
  border: solid;
  border-radius: 40px;
  margin-top: 30px;
  padding: 10px;
}

.btn-contact:link, .btn-contact:visited {
  color: #545454;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.btn-contact:hover {
  color: #fff;
  background-color: #f63372;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #00000026;
}

.btn-contact:active {
  transform: translateY(0);
  box-shadow: 0 1rem 1rem #00000026;
}

.btn-contact a {
  color: #fff;
  text-decoration: none;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.cuadrado {
  width: 15rem;
  height: 15rem;
  border-style: solid;
  border-color: #696969;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  70% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  70% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }

  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media (min-width: 600px) and (max-width: 899px) {
  .row [class^="col-"]:not(:last-child) {
    margin-bottom: 6rem;
    margin-right: 0;
  }

  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc(50% - 3rem);
}

.row .col-1-of-3 {
  width: calc(33.3333% - 4rem);
}

.row .col-2-of-3 {
  width: calc(66.6667% - 2rem);
}

.row .col-1-of-4 {
  width: calc(25% - 4.5rem);
}

.row .col-2-of-4 {
  width: calc(50% - 3rem);
}

.row .col-3-of-4 {
  width: calc(75% - 1.5rem);
}

.column_secondary {
  padding-top: 18rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media (min-width: 600px) and (max-width: 899px) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-text--rose {
  color: #f63372;
}

.u-align-center {
  align-self: center;
}

.u-space-pading--medium {
  padding: 5rem;
}

@media (max-width: 599px) {
  .u-space-pading--medium {
    padding: .5rem 0;
  }
}

.u-text--yellow {
  background-color: #fdd300;
  padding-left: .3rem;
  padding-right: 1.3rem;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.block_white, .block_grey {
  min-width: 100%;
  max-width: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.grid-block {
  width: 80vw;
}

h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

body, p {
  text-align: center;
  font-size: 1.6rem;
}

a {
  padding: 15px;
  font-size: 1.6rem;
}

.block_white {
  background-color: #fff;
}

.block_grey {
  background-color: #f4f4f4;
}

.icon-card {
  width: 4rem;
  height: 4rem;
}

.icon-media {
  width: 1rem;
  height: 1rem;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #696969;
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

@media (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  html {
    font-size: 75%;
  }
}

.custom-shape-divider-bottom-1664527124 {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1664527124 svg {
  width: calc(117% + 1.3px);
  height: 116px;
  display: block;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1664527124 .shape-fill {
  fill: #f4f4f4;
}

.laCanastaLogo {
  height: 64px !important;
}

.footer {
  width: 100%;
  background-color: #2e2b2b;
  flex-direction: row;
  margin-top: 0;
  margin-right: 0;
  padding: 20px 0;
  display: flex;
}

@media (max-width: 599px) {
  .footer {
    flex-direction: column;
  }
}

.footer__text {
  width: 50%;
  text-align: left;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .footer__text {
    width: 100%;
  }
}

.footer__text--box-1 {
  padding: 0 1rem;
}

@media (max-width: 599px) {
  .footer__text--box-1 {
    width: 50%;
  }
}

.footer__text--title {
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 599px) {
  .footer__text--title {
    font-size: 1.6rem;
  }
}

.footer__text--subtitle {
  width: 100%;
  text-align: left;
  color: #fff;
  padding-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: lighter;
}

@media (max-width: 599px) {
  .footer__text--subtitle {
    font-size: 1.6rem;
  }
}

.footer__text--subtitle--Peru {
  text-align: left;
  width: 35%;
  color: #fff;
  padding-bottom: 1rem;
  font-size: 1.3rem;
}

@media (max-width: 599px) {
  .footer__text--subtitle--Peru {
    width: 80%;
    font-size: 1.6rem;
  }
}

.footer__text--subtitle a {
  color: #fff;
  text-decoration: none;
}

.footer__logo {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .footer__logo {
    width: 100%;
  }
}

.footer__logo-box {
  top: 4rem;
  left: 4rem;
}

.footer__logo-box--img {
  height: 15rem;
}

.prefooter {
  width: 100%;
  background-color: #2e2b2b;
  flex-direction: row;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  display: flex;
}

@media (max-width: 599px) {
  .prefooter {
    flex-direction: column;
  }
}

.prefooter__logo {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .prefooter__logo {
    width: 100%;
  }
}

.prefooter__logo-box {
  justify-content: center;
  align-items: center;
  display: flex;
  top: 4rem;
  left: 4rem;
}

.prefooter__logo-box--img {
  height: 5rem;
  padding: 4rem;
}

.prefooter__logo-box--img2 {
  height: 9rem;
  padding: 4rem;
}

/*# sourceMappingURL=index.033cb76b.css.map */
