@import '../styles';

.btn-contact {
    /* max-width: 25%; */
    width: 30%;
    height: 3rem;
    color: $color-plain-white;
    background-color: $color-grey-dark;
    margin-top: 30px;
    border-radius: 40px;
    border: solid;
    padding: 10px;
    
    &:link,
    &:visited {
        font-size: $font-h3;
        color: $color-grey-dark-2;
        display: inline-block;
        text-decoration: none;
        //padding: 15px;
        transition: all .2s;
    }
    
    &:hover {
        background-color: $color-primary;
        color: $color-plain-white;
        box-shadow: 0 1rem 2rem rgba($color-plain-black, .15);
        transform: translateY(-3px);
    }
    
    &:active {
        box-shadow: 0 1rem 1rem rgba($color-plain-black, .15);
        transform: translateY(0);
    }
    
    a {
        color: $color-plain-white;
        text-decoration: none;
        
    }
    
}