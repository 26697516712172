@import '../../index.scss';

.header { 
  margin-top: 0;
  margin-right: 0;
  padding: 0;

  display: flex;
  flex-direction: row !important;
  width: 80%;
  @include phone {
      width: 100%;
      flex-direction: column !important;
  } 
  
  &__logo{
    width: 50%;
    display: flex;
    justify-content: center;
    //padding-top: 100px;
    align-items: center;
    @include phone {
      width: 100%;
      margin-left: 0;
      img {
        width: 70%;
      }
    } 
  }
  &__content {
    @include phone {
      display: flex;
      flex-direction: column;
    } 
  }
  &__text {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    //padding-top: 100px;
    @include phone {
        width: 100%;
        margin-top: 6rem;
    } 

    &-tittle {
      width: 80%;
      color: #000;
      text-align: left;
      @include phone {
        width: 80%;
        text-align: center;
        align-self: center;
      } 
    }

    &-p {
      width: 80%;
      text-align: left;
      @include phone {
        width: 80%;
        text-align: center;
        align-self: center;
      }    
    }

    &-button {
      width: 25%;
      margin-top: 30px;
      //padding: 15px;
      color: #fff;
      border: solid;
      border-radius: 30px;
      background-color: $color-grey-dark-2;
  
      a {
        font-size: 1.6rem;
      }
    }
  }
}

