@import '../../index.scss';

.blockMain {
    width: 100vw;
    height: 50vh;
}

.convierte_ideas { 
    margin-top: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    
    
    @include phone {
        width: 100%;
        flex-direction: column;
    }
    
    &__text {
        width: 50%;
        padding: 4rem;
        display: flex;
        justify-content: center;
        //align-items: center;
        flex-direction: column;
        text-align: left;
        @include phone {
            width: 100%;
        } 

        &--title {
            
            text-align: left;
            color: $color-plain-black;
            @include phone {
                width: 80%;
            } 
        }

        &--subtitle {
            width: 60%;
            text-align: left;
            @include phone {
                margin-top: 2rem;
                width: 80%;
            } 
        }
    }

    &__img {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 80%;
        }
        @include phone {
            width: 100%;
            margin-top: 4rem;
            img{
                width: 80%;
            }
        } 
    }
}

.trabajemos_juntos { 
    margin-top: 0;
    margin-right: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    @include phone {
        width: 100%;
        flex-direction: column;
    } 
    
    &__text {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 5rem;
        align-items: center;
        
        flex-direction: column;
        text-align: left;
        @include phone {
            width: 100%;
        } 

        &--title {
            
            text-align: left;
            color: $color-plain-black;
            @include phone {
                width: 80%;
            }
        }

        &--subtitle {
            width: 70%;
            text-align: left;
            @include phone {
                width: 80%;
            }
        }
    }

    &__img {
        width: 50%;
        display: flex;
        padding: 0;
        //justify-content: center;
        //align-items: center;
        img {
            width: 80%;
        }
        @include phone {
            width: 100%;
            margin-bottom: 4rem;
            img{
                width: 50%;
            }
        } 
    }
}

.tu_crecimiento { 
    margin-top: 0;
    margin-right: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    
    &__text {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: left;

        &--title {
            width: 35%;
            text-align: left;
            color: $color-plain-black;
        }

        &--subtitle {
            width: 35%;
            text-align: left;
        }
    }

    &__img {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.clientes { 
    margin-top: 0;
    margin-right: 0;
    width: 80%;

    h2 {
        padding: 25px;
        font-weight: normal;
    }
    p {
        font-size: 1.4rem;
    }
    .alliances{
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &--box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        img {
            margin: 15px;
        }
        .customers img {
            height: 38px;
        }
        .alliances img {
            padding: 10px;
            height: 54px;
        }
        .awards img {
            padding: 10px;
            height: 72px;
        }
        a {
            padding: 25px;
            width: 280px;
            text-align: center;
            text-decoration: none;
            color: #666;
            font-size: 1.1rem;
        }
    }
}


