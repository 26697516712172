@import '../../../index.scss';
@import '../../../styles/styles.scss';

.footer { 
    margin-top: 0;
    margin-right: 0;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $color-grey-dark-3;
    @include phone {
        flex-direction: column;
    } 

    &__text {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        text-align: left;
        @include phone {
            width: 100%;
        }

        &--box-1 {
            padding: 0 1rem;
            @include phone {
                width: 50%;
            }
        }

        &--title {
            width: 100%;
            text-align: left;
            font-weight: bold;
            color: $color-plain-white;
            font-size: 1.5rem;
            @include phone {
                font-size: $font-h3;
            }
        }

        &--subtitle {
            width: 100%;
            text-align: left;
            font-weight: lighter;
            color: $color-plain-white;
            padding-bottom: 1rem;
            font-size: 1.3rem;
            @include phone {
                font-size: $font-h3;
            }

            &--Peru {
                text-align: left;
                width: 35%;
                color: $color-plain-white;
                padding-bottom: 1rem;
                font-size: 1.3rem;
                @include phone {
                    width: 80%;
                    font-size: $font-h3;
                }
            }

            a {
                color: $color-plain-white;
                text-decoration: none;
            }
        }
    }

    &__logo {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include phone {
            width: 100%;
        }
        &-box {
            top: 4rem;
            left: 4rem;

            &--img {
                height: 15rem;
            }
        } 
    }
}

.prefooter { 
    margin-top: 0;
    margin-right: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $color-grey-dark-3;
    @include phone {
        flex-direction: column;
    } 

    &__logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include phone {
            width: 100%;
        }
        &-box {
            top: 4rem;
            left: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &--img {
                height: 5rem;
                padding: 4rem;
            }
            &--img2 {
                height: 9rem;
                padding: 4rem;
            }
        } 
    }
}